@import "../app/CommonStyles.scss";
@import "../app/tokens.scss";

.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $keva-unit $keva-unit;
    color: white;
    width: 100%;
    height: 60px;
    background-color: $topbar-color;
    box-sizing: border-box;
    h1{
        word-break: break-all;
    }

    .dummy {
        visibility: hidden;
    }
}
