@import "../../app/colors.scss";
@import "../../app/CommonStyles.scss";
@import "../../app/tokens.scss";

// .react-grid-item {
//     border: 1px dotted red;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

.react-resizable-handle {
    opacity: 0.3;
}

.dashboard {
    background-color: $dashboard-background;

    .pageloader-container {
        display: flex;
        flex-direction: column;

        .to-front {
            color: red;
            background-color: red;
            font-size: 200px;
            font-style: italic;
        }
    }

    &__modules {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .layout {
            .react-grid-item {
                background-color: $module-background-color;
                border-radius: 10px;
                // overflow-y: auto;
                // border: 1px solid black;
                & [class*=".react-draggable-dragging"] {
                    border: 3px dashed red;
                }
            }

            .react-grid-item.react-draggable-dragging,
            .has-dragged-child {
                // z-index: 1000 !important;
            }
        }
    }

    &__modules.highlighted {
        border: 3px dashed green;
    }

    &__module {
        margin: 8px;
    }
}

.latest-done-label {
    position:absolute;
    top: $keva-unit;
    right: -5px;
    background-color: lightgreen;
    border-radius: 10px;
    padding: $keva-unit / 2;
    rotate: 10deg;
}

.latest-done-label.late {
    background-color: lightcoral;
}

// .react-grid-item {
//     height: 100%;
// }

.react-grid-item.minimized {
    span {
        display: none;
    }
}
