@import "../../app/colors.scss";
@import "../../app/CommonStyles.scss";
@import "../../app/tokens.scss";

.menu-item {
    padding: $keva-unit 0;
    cursor: pointer;
    border-bottom: 1px solid grey;
    display: flex;
    width: 100%;
}

.modal-content > .box {
    background-color: $modal-box-background;
}

.box {
    display: flex;
    flex-direction: column;
    // border: 1px solid black;
    height:100%;
    padding: $keva-unit;
    overflow-y: auto;
    // margin: 10px;
    background-color: $box-content-background-color;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    .action-bar {
        min-width: 250px;
    }

    &_content {
        display: flex;
        // overflow-y: auto;
        // scrollbar-gutter: stable;
        width: 100%;
        height: 100%;
        // background-color: $primary-color;
        // background-color: rgb(238, 243, 233);
        flex-direction: column;

        // &--hovered {
        //     overflow-y: auto;
        // }
    }

    @media only screen and (max-width: 768px) {
        min-width: 0;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // background-color: $primary-color;
        // padding: $keva-unit * 0.5 $keva-unit;
        overflow: hidden;
        border-radius: 2px 2px 0 0;
        min-height: 45px;
        max-height: 45px;

        &--header-text {
            display: flex;
            width: 100%;
            font-size: 18px;
        }

        &--right-menu {
            display: flex;
            flex-direction: row;
        }
    }

    .footer{
        padding: $keva-unit;
    }
}